import React, { useState, useEffect, useCallback } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { useFormContext } from "react-hook-form";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";
import { useFormDataStore, useCustomerInfoStore } from "../../hooks/useSimpleOpState";

const M01 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  // @Model
  const {
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  const {
    wrapperClass,
    hideRequiredTextClass,
    titleText,
    requiredText,
    mobileClass,
    isHidden,
    placeholderText,
    placeholderTextDisable,
    showInput,
    inputTextPlaceholder,
    inputTextClass,
    isNeedEmptyPlaceholder,
    name,
    type,
    isDisabled
  } = properData;

  const [isHiddenField, setIsHiddenField] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  let options = props?.fields?.pulldownData;
  let formName = props?.fields?.formName ? props.fields.formName : "M01-1";
  let isRequired = props?.fields?.isRequired ? props.fields.isRequired : false;
  const formData = useFormDataStore((state) => state.formData);
  const customerInfo = useCustomerInfoStore((state) => state.customerInfo);
  let initialValue = properData?.initialValue;

  if(Object.keys(formData).length > 0 && type === "productAttributes" && formData[name]) {
    initialValue = 
    {
      name: titleText,
      value: formData[name]      
    };
  }
  if(Object.keys(customerInfo).length > 0 && type === "customerInfo" && customerInfo[name]) {
    initialValue = 
    {
      name: titleText,
      value: customerInfo[name]      
    };
  }
  // @Controller
  const handleOnChange = (event, myChangeEvent = undefined) => {
    setSelectedValue(event.target.value);
    if (myChangeEvent) {
      myChangeEvent(event);
    }
  };
  const handleInputTextChange = (evt) => {
    setInputValue(evt.target.value);
    if (properData?.onInputChangeEvent) {
      properData.onInputChangeEvent(evt);
    }
  };

  useEffect(() => {
    setIsHiddenField(isHidden);
  }, [isHidden]);

  useEffect(() => {
    if (initialValue?.value) {
      updateInitialValue(initialValue.value);
    }
    if (initialValue?.input) {
      setInputValue(initialValue.input);
    }
  }, [initialValue?.value]);

  const updateInitialValue = useCallback(() => {
    if (initialValue?.value) {
      setSelectedValue(initialValue.value);
    }
  }, [initialValue?.value]);

  const isPlaceHolderEmpty =
    !placeholderText?.value || placeholderText?.value === "–";

  // @View
  return (
    <React.Fragment>
      {!isHiddenField?.value ? (
        <div className={`input-box M-01 ${wrapperClass?.value}`}>
          <div className="input-form">
            <div className={`input-container ${mobileClass?.value}`}>
              <label
                className={hideRequiredTextClass?.value}
                htmlFor={formName}
              >
                {titleText?.value && <Text field={fillBlank(titleText)} />}

                {requiredText?.value && (
                  <span className="required">
                    <Text field={fillBlank(requiredText)} />
                  </span>
                )}
              </label>
              <div className="pull-down">
                <select
                  name={formName}
                  value={selectedValue}
                  {...register(formName, {
                    required: isRequired,
                  })}
                  onChange={(e) =>
                    handleOnChange(e, props?.fields?.onChangeEvent)
                  }
                  isDisabled={props?.disabled}
                >
                  {!isPlaceHolderEmpty && (
                    <option value="" disabled={placeholderTextDisable?.value}>
                      {fillBlank(placeholderText)?.value}
                    </option>
                  )}
                  {isNeedEmptyPlaceholder && <option value=""></option>}
                  {options?.map((item, i) => (
                    <option key={i} value={item["value"]}>
                      {item["name"]}
                    </option>
                  ))}
                </select>
                {showInput?.value && (
                  <input
                    type="text"
                    placeholder={fillBlank(inputTextPlaceholder)?.value}
                    className={inputTextClass}
                    value={inputValue}
                    onChange={handleInputTextChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default M01;
